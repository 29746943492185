import {
  Button,
  Divider,
  Image,
  message,
  Pagination,
  Popconfirm,
  Spin,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import { ExtractDate, ExtractTime, messageConfiguration } from "../../../Utils";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import "../Coupon/coupon.css";
import { useNavigate } from "react-router-dom";
import { deleteEvent, getevent } from "./EventApi";
import { UPLOAD_ENDPOINT } from "../../../ApiServices/Axios/Index";

function Event() {
  const [categoriesArray, setCategoriesArray] = useState([]);
  const [loadingMore, setLoadingMore] = useState(false);
  const [eventData, seteventData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [from, setFrom] = useState("add");
  const [filldata, setFilldata] = useState({});
  const [totalPage, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  function FetchEventList() {
    setIsLoading(true);
    getevent(`page=${currentPage}limit=10`)
      .then((response) => {
        if (response.status) {
          setIsLoading(false);
          seteventData(response?.data);
          setCategoriesArray(response?.categoriesArray);
          setTotalPages(response?.pagination?.totalPages);
          setCurrentPage(response?.pagination?.currentPage);
        }
      })
      .catch((err) => {
        console.log("error", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    FetchEventList();
  }, [currentPage]);

  function DeleteAction(record) {
    deleteEvent(`${record._id}`)
      .then((response) => {
        if (response.status) {
          message.open(messageConfiguration("success", response.message, 3));
          FetchEventList(currentPage);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const columns = [
    {
      title: "Cover Image",
      dataIndex: "coverImage",
      key: "coverImage",
      className: "custom-header font-dmsans text-sm",
      render: (data, record) => {
        return (
          <>
            <Image
              src={`${UPLOAD_ENDPOINT}${record.coverImage}`}
              height={100}
              width={100}
            />
          </>
        );
      },
    },
    {
      title: "Event Title",
      dataIndex: "title",
      key: "title",
      className: "custom-header font-dmsans text-sm",
      render: (data, record) => (
        <p className="text-xs font-dmsans">{record.title}</p>
      ),
    },
    {
      title: "Event SubTitle",
      dataIndex: "subtitle",
      key: "subtitle",
      className: "custom-header font-dmsans text-sm",
      render: (data, record) => (
        <p className="text-xs font-dmsans">{record.subtitle}</p>
      ),
    },
    {
      title: "Slug",
      dataIndex: "slug",
      key: "slug",
      className: "custom-header font-dmsans text-sm",
      render: (data, record) => (
        <p className="text-xs font-dmsans">{record.slug}</p>
      ),
    },
    {
      title: "Category",
      dataIndex: "categoryName",
      key: "categoryName",
      className: "custom-header font-dmsans text-sm",
      render: (data, record) => (
        <p className="text-xs font-dmsans">{record.categoryName}</p>
      ),
    },
    {
      title: "Venue",
      dataIndex: "venue",
      key: "venue",
      className: "custom-header font-dmsans text-sm",
      render: (data, record) => (
        <p className="text-xs font-dmsans">{record.venue}</p>
      ),
    },
    {
      title: "Start Time",
      dataIndex: "startTime",
      key: "startTime",
      className: "custom-header font-dmsans text-sm",
      render: (data, record) => (
        <p className="text-xs font-dmsans">
          {ExtractDate(record.startTime)}•{ExtractTime(record.startTime)}
        </p>
      ),
    },
    {
      title: "End Time",
      dataIndex: "endTime",
      key: "endTime",
      className: "custom-header font-dmsans text-sm",
      render: (data, record) => (
        <p className="text-xs font-dmsans">
          {ExtractDate(record.endTime)}•{ExtractTime(record.endTime)}
        </p>
      ),
    },
    {
      title: "Event Description",
      dataIndex: "description",
      key: "description",
      className: "custom-header font-dmsans text-sm",
      render: (data, record) => (
        <p className="text-xs font-dmsans">{record.description}</p>
      ),
    },
    {
      title: "Show Participants",
      dataIndex: "participants",
      key: "participants",
      className: "custom-header font-dmsans text-sm",
      render: (data, record) =>
        record.participants.length ? (
          <Button
            onClick={() =>
              navigate("/events/showParticipants", {
                state: {
                  fill: record.participants || [],
                },
              })
            }
            size="small"
            className="rounded-none p-[0px_32px_0px_32px] hover:bg-black text-white font-dmsans font-semibold flex  bg-orange text-xs "
          >
            Show Participants
          </Button>
        ) : (
          <p className="rounded-none p-[0px_32px_0px_32px] text-white font-dmsans font-semibold flex  bg-black text-xs">
            No Participants
          </p>
        ),
    },
    {
      title: "Edit",
      dataIndex: "edit",
      key: "edit",
      className: "custom-header font-dmsans text-sm",
      render: (data, record) => (
        <Button size="small" className="text-black text-sm">
          <EditOutlined
            onClick={() =>
              navigate("/addEvent", {
                state: {
                  fill: record,
                  from: "edit",
                },
              })
            }
          />
        </Button>
      ),
    },
    {
      title: "Delete",
      dataIndex: "delete",
      key: "delete",
      className: "custom-header font-dmsans text-sm",
      render: (data, record) => (
        <Popconfirm
          title={
            <div>
              <p style={{ marginBottom: "8px", fontWeight: "bold" }}>
                Are you sure you want to delete this Event History?
              </p>
            </div>
          }
          onConfirm={() => DeleteAction(record)}
          okText="Delete"
          cancelText="Cancel"
        >
          <DeleteOutlined className="text-xs font-dmsans" title="Delete" />
        </Popconfirm>
      ),
    },
  ];

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };
  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2  items-center m-2">
        <div className="ml-10">
          <p className="text-primary font-dmsans leading-7">Hello!</p>
          <p className="text-lg font-bold font-dmsans ">Create a Event</p>
        </div>
      </div>
      <div className="flex justify-end">
        <Button
          className="rounded-none p-[0px_32px_0px_32px] hover:bg-black text-white font-dmsans font-semibold flex  bg-orange text-xs "
          size="small"
          onClick={() =>
            navigate("/addEvent", {
              state: {
                from: "add",
                fill: "",
              },
            })
          }
        >
          Add Event
        </Button>
      </div>
      <div className="ml-10 md:ml-20 mt-8">
        {/* <AddEvent
          Fill={filldata}
          setFill={setFilldata}
          from={from}
          setFrom={setFrom}
          //   apiCall={FetchCouponList}
        /> */}
        <Divider
          orientation="left"
          className="text-lg font-dmsans font-semibold"
        >
          Event Details
        </Divider>
        <div
          style={{ maxHeight: "500px", overflow: "auto" }}
          className="bg-white"
        >
          <Table
            size="small"
            columns={columns}
            loading={isLoading}
            dataSource={eventData}
            className="w-screen sm:w-screen"
            // scroll={{
            //   y: 240,
            // }}
            pagination={false}
          />
        </div>
        <Pagination
          className="mt-2  w-full flex justify-center items-center "
          onChange={handlePageChange}
          current={currentPage}
          defaultCurrent={1}
          total={10 * totalPage}
          showSizeChanger={false}
        />
      </div>
    </>
  );
}
export default Event;
