import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Input, message, Modal } from "antd";
import axios from "axios";
import { UPLOAD_ENDPOINT } from "../../../../../ApiServices/Axios/Index";
import { presignedApi } from "../../CourseFun";
import { GrEdit } from "react-icons/gr";
import { BsUpload } from "react-icons/bs";
import IconButton from "../../../../../CommonComponents/IconButton";
import { MdDeleteOutline } from "react-icons/md";

const getVideoDuration = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const media = new Audio(reader.result);
      media.onloadedmetadata = () => resolve(Math.ceil(media.duration));
    };
    reader.readAsDataURL(file);
    reader.onerror = (error) => reject(error);
  });

const LectureModal = ({
  lecture,
  updateAsset,
  deleteAsset,
  index,
  lectureIndex,
  formData,
  setFormData,
}) => {
  const [form] = Form.useForm();
  const formRef = useRef(form);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [keyVid, setKeyVid] = useState();

  const [open, setOpen] = useState(false);

  const onOpen = () => {
    form.setFieldsValue({
      // title: lecture.title || "",
      link: lecture.link || "",
      previewLink: lecture.previewLink || "",
    });
    setOpen(true);
  };

  const onCancel = () => {
    setOpen(false);
  };

  const handleValuesChange = (changedValues) => {
    const data = {
      ...changedValues,
      link: keyVid,
      duration,
    };
    console.log(data);
    if (!data.duration || !data.link || !data.title || !data.previewLink) {
      message.error("Invalid Link or Preview Link passed", 2);
    } else {
      updateAsset(index, "lectures", lectureIndex, data);
      onCancel();
    }
  };
  useEffect(() => {
    if (!lecture.link || !lecture.title) {
      onOpen();
    }
  }, [lecture]);

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];

    try {
      if (!selectedFile) {
        console.error("No file selected.");
        return;
      }
      const duration = await getVideoDuration(selectedFile);
      setDuration(duration);
      // Get presigned URL and key
      const { preSignedUploadUrl, Key } = await getPresignedUrl(selectedFile);
      if (preSignedUploadUrl) {
        await uploadToPresignedUrl(preSignedUploadUrl, selectedFile);
        setKeyVid(Key);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const getFileExtension = (mimeType) => {
    const extension = mimeType.split("/").pop();
    return extension;
  };

  const getPresignedUrl = async (selectedFile) => {
    try {
      const fileExtension = getFileExtension(selectedFile.type);
      const res = await presignedApi(`?mimetype=${fileExtension}`);

      if (res.success) {
        return { preSignedUploadUrl: res.preSignedUploadUrl, Key: res.Key };
      }
    } catch (err) {
      console.error(err);
    }
    return { preSignedUploadUrl: null, Key: null };
  };

  const uploadToPresignedUrl = async (presignedUrl, selectedFile) => {
    const formData = new FormData();
    formData.append("file", selectedFile);
    try {
      await axios.put(presignedUrl, selectedFile, {
        headers: {
          "Content-Type": selectedFile.type,
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        },
      });
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };
  // useEffect(() => {
  //   form.setFieldsValue(formData);
  // }, [formData]);
  return (
    <>
      <Modal
        footer={null}
        open={open}
        onCancel={onCancel}
        title="Add lecture Details"
      >
        <Form
          className="bg-white p-6"
          layout="vertical"
          onFinishFailed={(err) => console.log(err)}
          onFinish={(changedValues) => {
            handleValuesChange(changedValues);
          }}
          form={form}
          ref={formRef}
        >
          <Form.Item
            name="title"
            label="Title"
            rules={[
              {
                required: true,
                message: "Please enter title",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="link" label="Link">
            {keyVid ? (
              <div className="h-[100px] aspect-video d-flex justify-center items-center relative">
                <video
                  src={`${UPLOAD_ENDPOINT}${keyVid}`}
                  autoPlay={false}
                  className=" h-[100px] aspect-video"
                >
                  Your browser does not support the video tag.
                </video>
              </div>
            ) : (
              <>
                <label
                  for="upload-photo"
                  className="rounded-none border flex justify-center items-center h-[100px] aspect-video"
                >
                  <div className="rounded-none border flex justify-center items-center h-[100px] aspect-video">
                    {uploadProgress > 0 && uploadProgress < 100 ? (
                      <div className="animate-pulse h-[100px] aspect-video">
                        <div
                          className={`bg-slate-300 h-[100px] flex flex-row justify-center items-center`}
                          style={{ width: `${uploadProgress}%` }}
                        >
                          <p>{`${uploadProgress}%`}</p>
                        </div>
                      </div>
                    ) : uploadProgress === 0 ? (
                      <div className="flex flex-col justify-center items-center h-[100px] aspect-video gap-2">
                        {/* upload icon */}
                        <BsUpload size={20} />
                        <p>Click to Upload</p>
                      </div>
                    ) : null}
                  </div>
                </label>
                <Input
                  type="file"
                  id="upload-photo"
                  style={{ opacity: "0", position: "absolute", zIndex: "-1" }}
                  onChange={handleFileChange}
                  accept="video/*"
                />
              </>
            )}
          </Form.Item>
          <Form.Item
            name="previewLink"
            label="Preview Link"
            rules={[
              {
                required: true,
                message: "Please enter preview link",
              },
            ]}
          >
            <Input type="text" />
          </Form.Item>
          <div>
            <Form.Item className="flex justify-center mt-4">
              <Button
                disabled={!keyVid}
                className="rounded-none p-[0px_32px_0px_32px] hover:bg-gray-100  font-dmsans font-semibold bg-orange text-xs"
                size="large"
                htmlType="onSubmit"
              >
                Add
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
      <div className="h-[50px] aspect-video d-flex justify-center items-center relative">
        {lecture && lecture.link && lecture.link.length ? (
          <div>
            <video
              src={`${UPLOAD_ENDPOINT}${lecture.link}`}
              autoPlay={false}
              className=" h-[50px] aspect-video"
            >
              Your browser does not support the video tag.
            </video>
            <div className="h-[50px] aspect-video flex flex-row justify-center items-center gap-2 absolute top-0 left-0">
              <IconButton Icon={GrEdit} onClick={onOpen} />
              <IconButton
                Icon={MdDeleteOutline}
                onClick={() => deleteAsset(index, "lectures", lectureIndex)}
              />
            </div>
          </div>
        ) : (
          <div className="animate-pulse">
            <div className="bg-slate-300 h-[50px] aspect-video">
              <div className="h-[50px] flex flex-row justify-center items-center gap-2">
                <IconButton Icon={GrEdit} onClick={onOpen} />
                <IconButton
                  Icon={MdDeleteOutline}
                  onClick={() => deleteAsset(index, "lectures", lectureIndex)}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default LectureModal;
