import { HomeFilled, NotificationFilled } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import { BsCalendarEventFill } from "react-icons/bs";
import { FaAddressBook } from "react-icons/fa";
import { MdDashboard } from "react-icons/md";
import { PiUserListFill } from "react-icons/pi";
import { RiCouponFill, RiLogoutBoxFill } from "react-icons/ri";
import { TbBoxAlignTopRightFilled } from "react-icons/tb";
import { NavLink, useNavigate } from "react-router-dom";

const blogMenu = (
  <Menu>
    <Menu.Item key="1">
      <NavLink to="/blog/blogCategory">Blog Category</NavLink>
    </Menu.Item>
    <Menu.Item key="2">
      <NavLink to="/blog/blogData">Blog Data</NavLink>
    </Menu.Item>
  </Menu>
);
export const MenuItems = () => {
  const list = [
    {
      key: "1",
      icon: <MdDashboard />,
      label: (
        <NavLink to={"/dashboard"}>
          <p>Dashboard</p>
        </NavLink>
      ),
    },
    {
      key: "2",
      icon: <FaAddressBook />,
      label: (
        <NavLink to={"/Courses"}>
          <p>Create Course</p>
        </NavLink>
      ),
    },
    {
      key: "3",
      icon: <RiCouponFill />,
      label: (
        <NavLink to={"/Coupon"}>
          <p>Coupon</p>
        </NavLink>
      ),
    },
    {
      key: "4",
      icon: <NotificationFilled />,
      label: (
        <NavLink to={"/Notification"}>
          <p>Notification</p>
        </NavLink>
      ),
    },
    {
      key: "6",
      icon: <PiUserListFill />,
      label: (
        <NavLink to={"/UsersList"}>
          <p>User List</p>
        </NavLink>
      ),
    },
    // {
    //   key: "7",
    //   icon: <TbBoxAlignTopRightFilled />,
    //   label: (
    //     <Dropdown overlay={blogMenu} trigger={["click"]}>
    //       <a onClick={(e) => e.preventDefault()}>
    //         <p>Blog</p>
    //       </a>
    //     </Dropdown>
    //   ),
    // },
    {
      key: "7",
      icon: <TbBoxAlignTopRightFilled />,
      label: (
        <NavLink to={"/blog/blogData"}>
          <p>Blog</p>
        </NavLink>
      ),
    },
    {
      key: "8",
      icon: <BsCalendarEventFill />,
      label: (
        <NavLink to={"/event"}>
          <p>Event</p>
        </NavLink>
      ),
    },
    {
      key: "9",
      icon: <HomeFilled />,
      label: (
        <NavLink to={"/addHomePage"}>
          <p>Home Page</p>
        </NavLink>
      ),
    },
  ];
  return list;
};

export const LogoutMenuItem = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  const list = [
    {
      key: "7",
      icon: <RiLogoutBoxFill />,
      label: (
        <div onClick={handleLogout} style={{ cursor: "pointer" }}>
          <p>Log Out</p>
        </div>
      ),
    },
  ];

  return list;
};
