import React, { useEffect, useState } from "react";
import { GrAdd } from "react-icons/gr";
import { DeleteOutlined, MenuOutlined } from "@ant-design/icons";
import { Button, Input, Modal } from "antd";

import Lectures from "./Lectures/Lectures";
import YtLinks from "./YouTube/Youtube";
import Docs from "./Document/Documents";

const Section = ({
  doc,
  index,
  addSection,
  updateSection,
  deleteSection,
  addAsset,
  updateAsset,
  deleteAsset,
  formData,
  setFormData,
}) => {
  console.log("Doc", doc);
  useEffect(() => {
    console.log(
      "================================",
      formData,
      formData.sections
    );
  }, [formData]);
  return (
    <>
      <div className="bg-gray-100 p-2 mt-2 ml-4">
        <div className="font-dmsans font-semibold  grid grid-cols-1 ">
          <div className="flex flex-row justify-between items-center mb-2">
            <div className="flex gap-4">
              <MenuOutlined />
              <p className="text-black">{`Section - ${doc.title}`}</p>
            </div>
            <div className="flex gap-2">
              <Button
                type="text"
                className="cursor-pointer rounded-none text-lg font-extralight font-dmsans text-[#FF6636] border-none bg-[#FFEEE8]"
                onClick={() => addSection(index)}
              >
                <GrAdd />
              </Button>
              <Button
                type="text"
                className="cursor-pointer rounded-none text-lg font-extralight font-dmsans text-[#FF6636] border-none bg-[#FFEEE8]"
                onClick={() => deleteSection(index)}
              >
                <DeleteOutlined />
              </Button>
            </div>
          </div>

          {["Title", "Preview"].map((v) => (
            <div className="grid grid-cols-12 place-items-center content-center gap-x-4 mb-2">
              <p className="col-span-2 text-xs text-right w-full">{v}</p>
              <Input
                className="col-span-10 w-full rounded-none border-none"
                value={doc[v.toLowerCase()]}
                onChange={(e) =>
                  updateSection(index, v.toLowerCase(), e.target.value)
                }
              />
            </div>
          ))}
          <Lectures
            lectures={doc.lectures}
            addAsset={addAsset}
            updateAsset={updateAsset}
            deleteAsset={deleteAsset}
            index={index}
            formData={formData}
            setFormData={setFormData}
          />
          <YtLinks
            ytLinks={doc.ytLinks}
            addAsset={addAsset}
            updateAsset={updateAsset}
            deleteAsset={deleteAsset}
            index={index}
            formData={formData}
            setFormData={setFormData}
          />
          <Docs
            docs={doc.docs}
            addAsset={addAsset}
            updateAsset={updateAsset}
            deleteAsset={deleteAsset}
            index={index}
            formData={formData}
            setFormData={setFormData}
          />
        </div>
        {doc.sections && doc.sections.length ? (
          <>
            {doc.sections.map((_doc, _index) => (
              <Section
                doc={_doc}
                index={`${index},${_index}`}
                addSection={addSection}
                updateSection={updateSection}
                deleteSection={deleteSection}
                addAsset={addAsset}
                updateAsset={updateAsset}
                deleteAsset={deleteAsset}
                formData={formData}
                setFormData={setFormData}
              />
            ))}
          </>
        ) : null}
      </div>
    </>
  );
};
export default Section;
