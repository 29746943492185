import { Button, Col, Divider, Form, Input, Row, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import "../Basic/basic.css";
import { DownOutlined } from "@ant-design/icons";

const Basic = ({ setFormData, formData, setActiveTab, activeTab }) => {
  const [form] = Form.useForm();
  const formRef = useRef(form);
  const handleValuesChange = (changedValues) => {
    setActiveTab("2");
    setFormData({ ...formData, ...changedValues });
  };
  useEffect(() => {
    form.setFieldsValue(formData);
  }, [formData]);
  return (
    <>
      <div className="ml-2 grid grid-cols-1 sm:grid-cols-2 ">
        <div>
          <p className="text-lg font-dmsans font-semibold">Basic Information</p>
        </div>
        {/* <div className="flex justify-end gap-2 mr-8">
          <p className="bg-[#ffeee8] text-sm border-none cursor-pointer text-[#FF6636] p-2 flex justify-center font-dmsans font-semibold rounded-none">Save</p>
          <p className="bg-white text-sm border-none cursor-pointer text-[#FF6636] p-2 flex justify-center font-dmsans font-semibold rounded-md">Save & Preview</p>
        </div> */}
      </div>
      <Divider />
      <div>
        <Form
          className="bg-white p-2 font-dmsans font-medium text-xs text-gray2"
          layout="vertical"
          onFinishFailed={(err) => console.log(err)}
          onFinish={(changedValues) => {
            handleValuesChange(changedValues);
          }}
          ref={formRef}
          form={form}
        >
          <Row className="grid grid-cols-1 ">
            <Col>
              <Form.Item
                name="title"
                label="
                  Title"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Title",
                  },
                ]}
              >
                <Input
                  suffix={
                    <span className="text-sm text-[#4E5566] font-normal font-dmsans">
                      0/80
                    </span>
                  }
                  placeholder="Your Course Title"
                  type="text"
                  style={{
                    width: "97.5%",
                  }}
                  className="rounded-none"
                  // className="rounded-none custom-placeholder text-gray2 font-dmsans text-base"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="grid grid-cols-1">
            <Col>
              <Form.Item
                name="subtitle"
                label="
                 Sub Title"
                rules={[
                  {
                    required: true,
                    message: "Please Enter SubTitle",
                  },
                ]}
              >
                <Input
                  suffix={
                    <span className="text-sm text-[#4E5566] font-normal font-dmsans">
                      0/120
                    </span>
                  }
                  placeholder="Your Course subtitle"
                  type="text"
                  style={{
                    width: "97.5%",
                  }}
                  className="rounded-none"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} className="grid grid-cols-1 ">
            <Col>
              <Form.Item
                name="category"
                label="Course Category"
                rules={[
                  {
                    required: true,
                    message: "Please select a course category",
                  },
                ]}
              >
                <Input
                  placeholder="What is primarily taught in your course"
                  type="text"
                  style={{
                    width: "97.5%",
                  }}
                  className="rounded-none"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="grid grid-cols-1">
            <Col>
              <Form.Item
                name="topic"
                label="
                  Course Topic"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Course Topic",
                  },
                ]}
              >
                <Input
                  placeholder="What is primarily taught in your course"
                  type="text"
                  style={{
                    width: "97.5%",
                  }}
                  className="rounded-none"

                  // className="rounded-none custom-placeholder text-gray2 font-dmsans text-base"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8} className="grid grid-cols-1 sm:grid-cols-3 ">
            <Col span={8} className="max-w-[93%]">
              <Form.Item
                name="language"
                label="Course Language"
                rules={[
                  {
                    required: true,
                    message: "Please enter course language",
                  },
                ]}
              >
                <Input
                  placeholder="Enter Course Language"
                  type="text"
                  className="rounded-none"
                />
              </Form.Item>
            </Col>
            <Col span={8} className="max-w-[93%]">
              <Form.Item name="subtitleLanguage" label="Subtitle Language ">
                <Input
                  placeholder="Enter Subtitle Language"
                  type="text"
                  className="rounded-none"

                  // className="rounded-none custom-placeholder text-gray2 font-dmsans text-base"
                />
              </Form.Item>
            </Col>
            <Col span={8} className="max-w-[93%]">
              <Form.Item
                name="level"
                label="Course Level"
                rules={[
                  {
                    required: true,
                    message: "Please select a course level",
                  },
                ]}
              >
                <Input
                  placeholder="Enter course level"
                  type="text"
                  // style={{
                  //   width: "97.5%",
                  // }}
                  className="rounded-none"

                  // className="rounded-none custom-placeholder text-gray2 font-dmsans text-base"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8} className="grid grid-cols-1 sm:grid-cols-3 ">
            <Col span={8} className="max-w-[93%]">
              <Form.Item
                suffixIcon={
                  <DownOutlined className="text-sm text-[#4E5566] font-normal font-dmsans" />
                }
                name="allowDownload"
                label="Download"
                rules={[
                  {
                    required: true,
                    message: "Please select a course category",
                  },
                ]}
              >
                <Select
                  suffixIcon={
                    <DownOutlined className="text-sm text-[#4E5566] font-normal font-dmsans" />
                  }
                  placeholder="Don't Allow"
                  options={[
                    { value: true, label: "Allow" },
                    { value: false, label: "Don't Allow" },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={8} className="max-w-[93%]">
              <Form.Item name="trialEnabled" label="Trial">
                <Select
                  suffixIcon={
                    <DownOutlined className="text-sm text-[#4E5566] font-normal font-dmsans" />
                  }
                  placeholder="Select..."
                  options={[
                    { value: true, label: "Enable" },
                    { value: false, label: "Disable" },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>

          <p className="text-lg font-dmsans font-semibold">Course Pricing</p>

          <Divider />
          <Row gutter={16} className="grid grid-cols-1 sm:grid-cols-3">
            <Col span={8} className="max-w-[96%]">
              <Form.Item
                name="price"
                label="Course Price"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Course Price",
                  },
                  {
                    pattern: /^[1-9]\d*(\.\d+)?$/,
                    message: "Please enter a valid price",
                  },
                ]}
              >
                <Input
                  placeholder="Enter Course Price"
                  type="text"
                  className="rounded-none"
                  style={{
                    width: "97.5%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8} className="max-w-[96%]">
              <Form.Item
                name="discount"
                label="Discount"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Course Discount",
                  },
                  {
                    pattern: /^[0-9]*$/,
                    message: "Please enter a valid discount price",
                  },
                ]}
              >
                <Input
                  placeholder="Enter Discount"
                  type="text"
                  className="rounded-none"
                  style={{
                    width: "97.5%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8} className="max-w-[96%]">
              <Form.Item
                name="installments"
                label="Create installments"
                rules={[
                  {
                    required: true,
                    message: "Please Select installments",
                  },
                ]}
              >
                <Select
                  suffixIcon={
                    <DownOutlined className="text-sm text-[#4E5566] font-normal font-dmsans" />
                  }
                  placeholder=""
                  defaultValue={formData.installments}
                  style={{
                    width: "97.5%",
                  }}
                  options={[1, 2, 3, 4, 5, 6].map((i) => ({
                    label: i.toString(),
                    value: i,
                  }))}
                  // onChange={handleSchoolTypeChange}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row className="flex justify-between max-w-[96%]">
            <Form.Item>
              <Button
                className="rounded-none p-[0px_32px_0px_32px]   text-sm font-dmsans font-semibold text-gray2 "
                size="large"
                htmlType="onSubmit"
                onClick={() => setActiveTab("1")}
              >
                Cancel
              </Button>
            </Form.Item>

            <Form.Item>
              <Button
                className="rounded-none p-[0px_32px_0px_32px] hover:bg-black text-white font-dmsans font-semibold  bg-orange text-xs "
                size="large"
                htmlType="submit"
              >
                Save & Next
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default Basic;
