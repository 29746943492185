import { Delete, Get, getAPIUrl, Post } from "../../../ApiServices/apiMethod";
import { HomePageService } from "../../../ApiServices/Axios/Index";

export const BlogEndpoint = {
  getBlog: `/lms/getBlogsAdmin?`,
  addBlog: `/lms/createBlog`,
  deleteBlog: `/lms/deleteBlog?eventId=`,
  editBlog: `/lms/updateBlog?getSlug=`,
};

export const getBlog = (param = "") => {
  const url = getAPIUrl(BlogEndpoint.getBlog, param);
  return Get(HomePageService, url, true);
};
export const addBlog = (data, param = "") => {
  const url = getAPIUrl(BlogEndpoint.addBlog, param);
  return Post(HomePageService, url, data, true);
};
export const editBlog = (data, param = "") => {
  const url = getAPIUrl(BlogEndpoint.editBlog, param);
  return Post(HomePageService, url, data, true);
};
export const deleteBlog = (param = "") => {
  const url = getAPIUrl(BlogEndpoint.deleteBlog, param);
  return Delete(HomePageService, url, param);
};
