import axios from "axios";
import {
  dispatchtoast,
  messageConfiguration,
  removeLocalStorageItem,
  showError,
} from "../../Utils";
import { message } from "antd";

export const version = "api/v1/";
export const AUTH_ENDPOINT =
  process.env.REACT_APP_NODE_ENV === "staging"
    ? `https://staging.auth.equity2commodity.com/api/v1/`
    : "https://prod.auth.equity2commodity.com/api/v1/";

export const AuthService = axios.create({
  // withCredentials: true,
  baseURL: AUTH_ENDPOINT,
});

const handleResponse = (response) => {
  if (response.data.status || response.data.success) {
    return response.data;
  } else if (response.data.formErrors) {
    showError(response.data.formErrors);
  } else if (response.data.validationError) {
    showError(response.data.validationError);
  } else {
    dispatchtoast(response.data.message ?? response.data.error, true);
  }
  return response.data;
};
const handleError = (error) => {
  const { status } = error?.response;
  if (status === 401) {
    dispatchtoast(
      error?.response.data.message ?? error?.response.data.msg,
      true
    );
    removeLocalStorageItem("isLoggedIn");
    removeLocalStorageItem("accessToken");
    window.location.href = "/";
  }
  if (status && status >= 400 && status < 500) {
    if (error.response.data.formErrors) {
      showError(error.response.data.formErrors);
    } else if (error.response.data.validationError) {
      showError(error.response.data.validationError);
    } else {
      dispatchtoast(
        error?.response.data.message ?? error?.response.data.error,
        true
      );
    }
  } else if (status && status >= 500) {
    dispatchtoast(
      error?.response.data.message ?? error?.response.data.msg,
      true
    );
  }
  return Promise.reject(error);
};
// Add a request interceptor
AuthService.interceptors.request.use((config) => {
  // Add the JWT token to the request headers
  // if (jwtToken) {
  //   config.headers['Authorization'] = `jwt_token=${jwtToken}`;
  // }
  // Add other headers if needed
  // config.headers['X-Timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return config;
});
// Add a response interceptor
AuthService.interceptors.response.use(
  (response) => handleResponse(response),
  (error) => {
    // Handle errors if needed
    handleError(error);
  }
);
export const UPLOAD_ENDPOINT =
  process.env.REACT_APP_NODE_ENV === "staging"
    ? `https://staging.content.equity2commodity.com/`
    : "https://prod.content.equity2commodity.com/";

export const COURSE_ENDPOINT =
  process.env.REACT_APP_NODE_ENV === "staging"
    ? `https://staging.course.equity2commodity.com/api/v1/`
    : "https://prod.course.equity2commodity.com/api/v1/";

export const CourseService = axios.create({
  // withCredentials: true,
  baseURL: COURSE_ENDPOINT,
});

// Add a request interceptor
CourseService.interceptors.request.use((config) => {
  // Add the JWT token to the request headers
  // if (jwtToken) {
  //   config.headers['Authorization'] = `jwt_token=${jwtToken}`;
  // }
  // Add other headers if needed
  config.headers["X-Timezone"] =
    Intl.DateTimeFormat().resolvedOptions().timeZone;
  return config;
});
// Add a response interceptor
CourseService.interceptors.response.use(
  (response) => handleResponse(response),
  (error) => {
    // Handle errors if needed
    handleError(error);
  }
);

export const COUPON_ENDPOINT =
  process.env.REACT_APP_NODE_ENV === "staging"
    ? `https://staging.order.equity2commodity.com/api/v1/`
    : "https://prod.order.equity2commodity.com/api/v1/";

export const CouponService = axios.create({
  // withCredentials: true,
  baseURL: COUPON_ENDPOINT,
});

// Add a request interceptor
CouponService.interceptors.request.use((config) => {
  // Add the JWT token to the request headers
  // if (jwtToken) {
  //   config.headers['Authorization'] = `jwt_token=${jwtToken}`;
  // }
  // Add other headers if needed
  config.headers["X-Timezone"] =
    Intl.DateTimeFormat().resolvedOptions().timeZone;
  return config;
});
// Add a response interceptor
CouponService.interceptors.response.use(
  (response) => handleResponse(response),
  (error) => {
    // Handle errors if needed
    handleError(error);
  }
);

export const USER_ENDPOINT =
  process.env.REACT_APP_NODE_ENV === "staging"
    ? `https://staging.user.equity2commodity.com/api/v1/`
    : "https://prod.user.equity2commodity.com/api/v1/";

export const UserService = axios.create({
  // withCredentials: true,
  baseURL: USER_ENDPOINT,
});

// Add a request interceptor
UserService.interceptors.request.use((config) => {
  // Add the JWT token to the request headers
  // if (jwtToken) {
  //   config.headers['Authorization'] = `jwt_token=${jwtToken}`;
  // }
  // Add other headers if needed
  config.headers["X-Timezone"] =
    Intl.DateTimeFormat().resolvedOptions().timeZone;
  return config;
});
// Add a response interceptor
UserService.interceptors.response.use(
  (response) => handleResponse(response),
  (error) => {
    // Handle errors if needed
    handleError(error);
  }
);
export const NOTIFICATION_ENDPOINT =
  process.env.REACT_APP_NODE_ENV === "staging"
    ? `https://staging.notification.equity2commodity.com/api/v1/`
    : "https://prod.notification.equity2commodity.com/api/v1/";

export const NotifyService = axios.create({
  // withCredentials: true,
  baseURL: NOTIFICATION_ENDPOINT,
});

// Add a request interceptor
NotifyService.interceptors.request.use((config) => {
  // Add the JWT token to the request headers
  // if (jwtToken) {
  //   config.headers['Authorization'] = `jwt_token=${jwtToken}`;
  // }
  // Add other headers if needed
  config.headers["X-Timezone"] =
    Intl.DateTimeFormat().resolvedOptions().timeZone;
  return config;
});
// Add a response interceptor
NotifyService.interceptors.response.use(
  (response) => handleResponse(response),
  (error) => {
    // Handle errors if needed
    handleError(error);
  }
);
//TemporarilyService
export const HOMEPAGE_ENDPOINT =
  process.env.REACT_APP_NODE_ENV === "staging"
    ? "https://staging.cms.equity2commodity.com/api/v1/"
    : "https://prod.cms.equity2commodity.com/api/v1/";
export const HomePageService = axios.create({
  // withCredentials: true,
  baseURL: HOMEPAGE_ENDPOINT,
});

// Add a request interceptor
HomePageService.interceptors.request.use((config) => {
  // Add the JWT token to the request headers
  // if (jwtToken) {
  //   config.headers['Authorization'] = `jwt_token=${jwtToken}`;
  // }
  // Add other headers if needed
  config.headers["X-Timezone"] =
    Intl.DateTimeFormat().resolvedOptions().timeZone;
  return config;
});
// Add a response interceptor
HomePageService.interceptors.response.use(
  (response) => handleResponse(response),
  (error) => {
    // Handle errors if needed
    handleError(error);
  }
);
