import { Button, Divider, Image, Pagination, Popconfirm, Table } from "antd";
import React, { useState } from "react";
import { ExtractDate, ExtractTime } from "../../../Utils";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import "../Coupon/coupon.css";
import { useLocation, useNavigate } from "react-router-dom";
import { UPLOAD_ENDPOINT } from "../../../ApiServices/Axios/Index";

function ShowParticipants() {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const [totalPage, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const fill = location?.state?.fill || [];
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      className: "custom-header font-dmsans text-sm",
      render: (data, record) => (
        <p className="text-xs font-dmsans">{record.name}</p>
      ),
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
      className: "custom-header font-dmsans text-sm",
      render: (data, record) => (
        <p className="text-xs font-dmsans">{record.email}</p>
      ),
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
      className: "custom-header font-dmsans text-sm",
      render: (data, record) => (
        <p className="text-xs font-dmsans">{record.contact}</p>
      ),
    },
    {
      title: "location",
      dataIndex: "location",
      key: "location",
      className: "custom-header font-dmsans text-sm",
      render: (data, record) => (
        <p className="text-xs font-dmsans">{record.location}</p>
      ),
    },
  ];

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };
  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2  items-center m-2">
        <div className="ml-10">
          <p className="text-primary font-dmsans leading-7">Hello!</p>
          <p className="text-lg font-bold font-dmsans ">Event Participants</p>
        </div>
      </div>

      <div className="ml-10 md:ml-20 mt-8">
        <Divider
          orientation="left"
          className="text-lg font-dmsans font-semibold"
        >
          Event Participants Details
        </Divider>
        <div
          style={{ maxHeight: "500px", overflow: "auto" }}
          className="bg-white"
        >
          <Table
            size="small"
            columns={columns}
            loading={isLoading}
            dataSource={fill??[]}
            className="w-screen sm:w-screen"
            // scroll={{
            //   y: 240,
            // }}
            pagination={false}
          />
        </div>
        {/* <Pagination
          className="mt-2  w-full flex justify-center items-center "
          onChange={handlePageChange}
          current={currentPage}
          defaultCurrent={1}
          total={10 * totalPage}
          showSizeChanger={false}
        /> */}
      </div>
    </>
  );
}
export default ShowParticipants;
