import React, { Suspense, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Images } from "../../ImageController/Index";
import { Button, Form, Input, Spin } from "antd";
import { emailPattern, makeLabel, setLocalStorageItem } from "../../Utils";
import CommonInput from "../../CommonComponents/CommonInput";
import { FaArrowRight } from "react-icons/fa";
import { FacebookAuth, GoogleAuth, userLogin } from "./AuthFun";
import { NavLink, useNavigate } from "react-router-dom";

const Login = () => {
  const [btnLoading, setBtnLoading] = useState(false);
  const navigate = useNavigate();
  const handleLogin = (val) => {
    setBtnLoading(true);
    userLogin(val)
      .then((res) => {
        if (res.status) {
          setLocalStorageItem("accessToken", res.token);
          setLocalStorageItem("IsLoggesIn", true);
          navigate("/dashboard");
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };
  const handleGoogleAuth = () => {
    GoogleAuth()
      .then((res) => {
        window.open(res.url, "_self");
      })
      .catch((err) => console.log(err));
  };
  const handleFacebookAuth = () => {
    FacebookAuth()
      .then((res) => {
        window.open(res.url, "_self");
      })
      .catch((err) => console.log(err));
  };
  return (
    <Suspense
      fallback={
        <div className="flex justify-center items-center h-screen">
          <Spin spinning={true}></Spin>
        </div>
      }
    >
      <div className="container flex justify-center items-center h-screen">
        <div className="grid grid-cols-2 place-items-center ">
          <div className="w-full bg-authBg h-screen hidden md:flex justify-center items-center ">
            <LazyLoadImage
              src={Images.Login}
              alt="signupimage"
              className="w-[70%]"
            />
          </div>
          <div className="w-full h-screen flex justify-center items-center col-span-2 md:col-span-1">
            <div className="w-3/4 m-auto">
              <div className="font-poppins text-center text-xl font-bold">
                <p>Sign in to your account</p>
              </div>
              <Form onFinish={(val) => handleLogin(val)}>
                <Form.Item
                  name={"email"}
                  rules={[
                    {
                      required: true,
                      message: "Email Is Required",
                    },
                    {
                      pattern: emailPattern,
                      message: "Please Enter Valid Email",
                    },
                  ]}
                  labelCol={{ span: 24 }}
                  label={makeLabel("Email")}
                >
                  <CommonInput placeHolder="Username or email address..." />
                </Form.Item>
                <Form.Item
                  name={"password"}
                  rules={[
                    {
                      required: true,
                      message: "Password Is Required",
                    },
                  ]}
                  labelCol={{ span: 24 }}
                  label={makeLabel("Password")}
                >
                  <Input.Password
                    className="rounded-none  hover:border hover:border-gray-300 hover:shadow-none focus-within:border focus-within:border-gray-300 focus:border focus:border-gray-300"
                    placeholder="Password"
                  />
                </Form.Item>

                <div className="flex justify-end items-center">
                  <Button
                    loading={btnLoading}
                    type="primary"
                    iconPosition="end"
                    icon={<FaArrowRight />}
                    htmlType="submit"
                    style={{ color: "white" }}
                    prefix="jj"
                    className="font-poppins text-white font-bold rounded-none bg-primary"
                  >
                    Sign In
                  </Button>
                </div>
              </Form>
              {/* <NavLink to={"/signup"}>
            <p className="text-gray1 mt-3 cursor-pointer text-sm font-poppins text-center">
               Don't have an account? Sign Up
              </p>
            </NavLink> */}
            </div>
          </div>
        </div>
      </div>
    </Suspense>
  );
};

export default Login;
