import React, { useEffect, useState } from "react";
import { Button, Divider, Row } from "antd";
import { v4 as uuid } from "uuid";
import Section from "./Sectiontree";

const templates = {
  lectures: {
    title: "lecture",
    link: "",
    previewLink: "",
    duration: 0,
  },
  ytLinks: {
    title: "ytLink",
    link: "",
    previewLink: "",
    duration: 0,
  },
  docs: {
    title: "doc",
    link: "",
  },
};

const template = {
  title: "",
  preview: "",
  lectures: [],
  ytLinks: [],
  docs: [],
  sections: [],
};

const Curriculum = ({ setFormData, formData, setActiveTab }) => {
  const [sections, setSections] = useState([]);

  useEffect(() => {
    if (formData && formData.sections) {
      setSections(formData.sections);
    }
  }, [formData]);

  const addSection = (_id) => {
    const id = uuid().split("-")[0];
    if (!_id) {
      setSections([...sections, { ...template, id }]);
    } else {
      let map = _id.split(",");
      let temp = sections;
      let target = temp[map[0]];
      map.shift();
      if (map.length) {
        for (let i = 0; i <= map.length; i++) {
          target = target.sections[map[i]];
          if (i === map.length - 1) {
            delete target.lectures;
            delete target.ytLinks;
            delete target.docs;
            target.sections = [...target.sections, { ...template, id }];
            setSections([...sections]);
          }
        }
      } else {
        delete target.lectures;
        delete target.ytLinks;
        delete target.docs;
        target.sections = [...target.sections, { ...template, id }];
        setSections([...sections]);
      }
    }
  };

  const deleteSection = (_id) => {
    let map = _id.split(",");
    let temp = [...sections];
    if (map.length === 1) {
      temp[map[0]] = null;
      setSections([...temp.filter((v) => v)]);
    } else if (map.length === 2) {
      const firstIndex = map[0];
      const secondIndex = map[1];
      let target = temp[firstIndex];
      target.sections[secondIndex] = null;
      target.sections = target.sections.filter((v) => v);
      if (target.sections.length === 0) {
        target.lectures = [];
        target.ytLinks = [];
        target.docs = [];
        target.sections = [];
      }
      setSections([...temp]);
    } else {
      const firstIndex = map[0];
      let target = temp[firstIndex];
      map.shift();
      for (let i = 0; i <= map.length - 1; i++) {
        target = target.sections[map[i]];
        if (i === map.length - 2) {
          target.sections[map[i + 1]] = null;
          target.sections = target.sections.filter((v) => v);
          if (target.sections.length === 0) {
            target.lectures = [];
            target.ytLinks = [];
            target.docs = [];
            target.sections = [];
          }
          setSections([...temp]);
        }
      }
    }
  };

  const updateSection = (_id, f, v) => {
    let map = _id.split(",");
    let temp = [...sections];
    if (map.length === 1) {
      temp[map[0]][f] = v;
      setSections([...temp]);
    } else if (map.length === 2) {
      const firstIndex = map[0];
      const secondIndex = map[1];
      let target = temp[firstIndex];
      target.sections[secondIndex][f] = v;
      setSections([...temp]);
    } else {
      const firstIndex = map[0];
      let target = temp[firstIndex];
      map.shift();
      for (let i = 0; i <= map.length - 1; i++) {
        target = target.sections[map[i]];
        if (i === map.length - 2) {
          target.sections[map[i + 1]][f] = v;
          setSections([...temp]);
        }
      }
    }
  };

  const addAsset = (_id, asset) => {
    let map = _id.split(",");
    let temp = sections;
    let target = temp[map[0]];
    map.shift();
    if (map.length) {
      for (let i = 0; i <= map.length; i++) {
        target = target.sections[map[i]];
        if (i === map.length - 1) {
          target[asset] = [...target[asset], { ...templates[asset] }];
          setSections([...temp]);
        }
      }
    } else {
      target[asset] = [...target[asset], { ...templates[asset] }];
      setSections([...temp]);
    }
  };

  const updateAsset = (_id, asset, assetIndex, v) => {
    let map = _id.split(",");
    let temp = sections;
    let target = temp[map[0]];
    map.shift();

    if (map.length) {
      for (let i = 0; i <= map.length; i++) {
        target = target.sections[map[i]];
        if (i === map.length - 1) {
          target[asset][assetIndex] = v;
          setSections([...temp]);
        }
      }
    } else {
      target[asset][assetIndex] = v;
      console.log({ map, assetIndex, target, temp });
      setSections([...temp]);
    }
  };

  const deleteAsset = (_id, asset, assetIndex) => {
    let map = _id.split(",");
    let temp = sections;
    let target = temp[map[0]];
    map.shift();
    if (map.length) {
      for (let i = 0; i <= map.length; i++) {
        target = target.sections[map[i]];
        if (i === map.length - 1) {
          target[asset] = target[asset].filter((v, _i) => _i !== assetIndex);
          setSections([...temp]);
        }
      }
    } else {
      target[asset] = target[asset].filter((v, _i) => _i !== assetIndex);
      setSections([...temp]);
    }
  };

  const handleFinalSectionSubmit = () => {
    setFormData({ ...formData, sections });
    setActiveTab("4");
  };

  useEffect(() => {
    if (sections.length === 0 && formData.sections.length === 0) {
      addSection();
    } else {
      setSections([...formData.sections]);
    }
    console.log("Prefilled FormData", formData);
  }, []);

  return (
    <>
      <div>
        <div className="flex flex-row justify-between items-center">
          <p className="text-lg font-dmsans font-semibold">Course Curriculum</p>
        </div>
        <div>
          {sections.map((doc, index) => (
            <Section
              key={index}
              doc={doc}
              sections={sections}
              setSections={setSections}
              index={`${index}`}
              addSection={addSection}
              updateSection={updateSection}
              deleteSection={deleteSection}
              addAsset={addAsset}
              updateAsset={updateAsset}
              deleteAsset={deleteAsset}
              formData={formData}
              setFormData={setFormData}
            />
          ))}
        </div>
        <Divider />
        <div className="flex justify-center">
          <p
            onClick={() => addSection()}
            title="Add Section"
            className="bg-[#FFEEE8] mt-4 w-full text-sm border-none cursor-pointer text-[#FF6636] p-2 flex justify-center font-dmsans font-semibold rounded-none"
          >
            Add Section
          </p>
        </div>

        <Row gutter={16} className="flex justify-between p-6">
          <Button
            className="rounded-none p-[0px_32px_0px_32px] text-sm font-dmsans font-semibold text-gray2"
            size="large"
            htmlType="button"
            onClick={() => {
              setActiveTab("2");
            }}
          >
            Previous
          </Button>
          <Button
            className="rounded-none p-[0px_32px_0px_32px] hover:bg-black text-white font-dmsans font-semibold bg-orange text-xs"
            size="large"
            onClick={handleFinalSectionSubmit}
          >
            Save & Next
          </Button>
        </Row>
      </div>
    </>
  );
};

export default Curriculum;
