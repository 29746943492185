import { lazy } from "react";
import ProtectedAuth from "./PrivateAuth";
import ProtectedCourseDetail from "../Views/Main/CourseDetail/ProtectedCourseDetail";
import HomePage from "../Views/Main/HomePage";
import Event from "../Views/Main/Event";
import AddHomePage from "../Views/Main/HomePage/AddHomePage";
import Blog from "../Views/Main/Blog";
import AddBlog from "../Views/Main/Blog/AddBlog";
import AddEvent from "../Views/Main/Event/AddEvent";
import AddBlogCategory from "../Views/Main/Blog/AddBlogCategory";
import ShowParticipants from "../Views/Main/Event/ShowParticipants";
const Users = lazy(() => import("../Views/Main/Users"));
const Notification = lazy(() => import("../Views/Main/Notification"));
const Coupon = lazy(() => import("../Views/Main/Coupon"));

const Dashboard = lazy(() => import("../Views/Main/Dashboard/Dashboard"));
const Courses = lazy(() => import("../Views/Main/Course/Courses"));

export const PrivateAuth = () => {
  return [
    {
      element: <ProtectedAuth />,
      children: [
        {
          path: "/Dashboard",
          element: <Dashboard />,
        },
        {
          path: "/Courses",
          element: <Courses />,
        },
        {
          path: "/Coupon",
          element: <Coupon />,
        },
        {
          path: "/Notification",
          element: <Notification />,
        },
        {
          path: "/UsersList",
          element: <Users />,
        },
        {
          path: "/courseDetails",
          element: <ProtectedCourseDetail />,
        },
        {
          path: "/blog/blogData",
          element: <Blog />,
        },
        // {
        //   path: "/blog/blogCategory",
        //   element: <AddBlogCategory />,
        // },
        {
          path: "/blog/addBlog",
          element: <AddBlog />,
        },
        {
          path: "/event",
          element: <Event />,
        },
        {
          path: "/addEvent",
          element: <AddEvent />,
        },
        {
          path: "/events/showParticipants",
          element: <ShowParticipants />,
        },

        {
          path: "/addHomePage",
          element: <AddHomePage />,
        },
        {
          path: "*",
          element: <Dashboard />,
        },
      ],
    },
  ];
};
